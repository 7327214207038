import React from 'react';
import { useStore } from '../../contexts/storeContext';
import { withTranslation, WithTranslationType } from '../../server/i18n';

import ProgressiveImage from '../../components/ProgressiveImage';

import { TRACKER_CATEGORY_HOME_LANDING_PAGE } from '../../helpers/trackerConstants';

import './home-testimonials.scss';

const HomeTestimonials = ({ t }: WithTranslationType): JSX.Element => {
  const {
    config,
    i18nUtils: { umRegion },
  } = useStore();

  const testimonials = config.getValue('hero.portal.home.testimonials', umRegion);

  return testimonials ? (
    <>
      <div className="home-testimonials new-container">
        <h1 className="home-testimonials__title" data-qa="testimonials-title">
          {t('home_testimonials-title')}
        </h1>
      </div>
      <div className="home-testimonials new-container">
        <div className="columns is-mobile home-testimonials__scrollable-content">
          {(testimonials.first && testimonials.first.imageUrl) ? (
            <div className="column is-4 home-testimonials__card">
              <Card
                imageUrl={testimonials.first.imageUrl}
                name={testimonials.first.name}
                text={testimonials.first.text}
              />
            </div>
          ) : null}
          {(testimonials.second && testimonials.second.imageUrl) ? (
            <div className="column is-4 home-testimonials__card">
              <Card
                imageUrl={testimonials.second.imageUrl}
                name={testimonials.second.name}
                text={testimonials.second.text}
              />
            </div>
          ) : null}
          {(testimonials.third && testimonials.third.imageUrl) ? (
            <div className="column is-4 home-testimonials__card">
              <Card
                imageUrl={testimonials.third.imageUrl}
                name={testimonials.third.name}
                text={testimonials.third.text}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  ) : null;
};

interface CardProps {
  imageUrl: string;
  name: string;
  text: string;
}

const Card = ({ imageUrl, name, text }: CardProps) => {
  const { tracker } = useStore();
  const onPersonClick = () => {
    tracker.track('Clicked on person testimonial', {
      category: TRACKER_CATEGORY_HOME_LANDING_PAGE,
    });
  };

  return (
    <div className="home-testimonials__card" onClick={onPersonClick}>
      <div className="home-testimonials__image">
        <ProgressiveImage
          altText=""
          disableWebp
          highResImg={imageUrl.replace('.jpg', '_3x.jpg')}
          highResImg2x={imageUrl.replace('.jpg', '_3x.jpg')}
          lowResImg={imageUrl.replace('/upload', '/upload/e_blur:2000,q_15')}
        />
      </div>
      <div className="home-testimonials__text" dangerouslySetInnerHTML={{ __html: text }} />
      <span className="home-testimonials__name">{name}</span>
    </div>
  );
};

export default withTranslation('home')(HomeTestimonials);
