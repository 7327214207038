import React, { Fragment, useState } from 'react';

import ActiveTabContent from '../../../components/home/tabs/ActiveTabContent';
import Tabs from '../../../components/home/tabs/Tabs';
import VerticalThumbnail from './HomeVerticalThumbnail';

import { withTranslation, WithTranslationType } from '../../../server/i18n';
import { useStore } from '../../../contexts/storeContext';
import { truncateWords } from '../../../helpers';
import { TRACKER_CATEGORY_HOME_LANDING_PAGE } from '../../../helpers/trackerConstants';

const MAX_SPECIALITIES = 12;

interface Props extends WithTranslationType {
  city: string;
}

interface IVerticalConfig {
  earnings_subtext: string;
  earnings_text: string;
  image_url: string;
  name: string;
  treatments: string;
}

export const ActiveCityTabContent = ({ city, t }: Props): JSX.Element => {
  const { cmsContent, tracker } = useStore();
  const verticalConfig = cmsContent.getValue('cities_and_services');

  const currentCityVerticalsConfig =
    verticalConfig && verticalConfig[city] && verticalConfig[city].map(i => i.service);
  const currentVertical = currentCityVerticalsConfig && currentCityVerticalsConfig[0].name;
  const [activeVertical, setActiveVertical] = useState(currentVertical);

  if (
    currentCityVerticalsConfig &&
    !currentCityVerticalsConfig.find((i: IVerticalConfig) => i.name === activeVertical)
  ) {
    setActiveVertical(currentVertical);

    tracker.track('Default vertical set', {
      category: TRACKER_CATEGORY_HOME_LANDING_PAGE,
      vertical: currentVertical,
    });
  }
  const activeVerticalConfig =
    currentCityVerticalsConfig &&
    currentCityVerticalsConfig.find((i: IVerticalConfig) => i.name === activeVertical);

  const verticalsList =
    currentCityVerticalsConfig &&
    currentCityVerticalsConfig.map(vertical => {
      return {
        className: 'home-explore-cities__tab',
        id: vertical.name,
        name: t(`home_explore-services-${vertical.name.toLowerCase()}`),
        titleContent: (
          <VerticalThumbnail
            id={vertical.name}
            imageUrl={vertical.image_url}
            isActive={vertical.name === activeVertical}
            setActiveVertical={setActiveVertical}
            title={t(`home_explore-services-${vertical.name.toLowerCase()}`)}
          />
        ),
      };
    });

  return (
    <Fragment>
      <Tabs
        activeTab={activeVertical}
        className="home-explore-cities__tabs"
        tabList={verticalsList}
        wrapperClassName="home-explore-cities__verticals"
      />
      <ActiveTabContent>
        <div className="columns">
          <Treatments
            activeVertical={activeVertical}
            city={city}
            title={t('home_explore-cities-treatments-title')}
          />
          {activeVerticalConfig && activeVerticalConfig.earnings_text && (
            <section className="column is-6 home-explore-cities__earnings">
              <h3 className="home-explore-cities__subtitle">
                {t('home_explore-cities-earnings-title')}
              </h3>
              <div className="home-explore-cities__earnings--text">
                {activeVerticalConfig.earnings_text}
              </div>
              <div className="home-explore-cities__earnings--subtext">
                {activeVerticalConfig.earnings_subtext}
              </div>
            </section>
          )}
        </div>
      </ActiveTabContent>
    </Fragment>
  );
};

interface TreatmentsProps extends WithTranslationType {
  activeVertical: string;
  city: string;
  title: string;
}

const Treatments = withTranslation('specialities')(
  ({ activeVertical, city, t, title }: TreatmentsProps) => {
    const { config, i18nUtils } = useStore();
    let treatments: string[] = [];
    let treatmentsText: string;

    switch (activeVertical) {
      case 'MASSAGE':
        treatments = config.getValue(
          'hero.portal.signup.massage-treatment-list',
          i18nUtils.umRegion,
          city,
        );
        break;
      case 'BEAUTY_AND_HAIR':
        treatments = config.getValue(
          'hero.portal.signup.beauty-treatments-list',
          i18nUtils.umRegion,
          city,
        );
        if (city === 'UK-LONDON') {
          treatmentsText =
            'Manicures and Pedicures, Gel / Shellac Nails, Facials, Waxing, Eyelash extensions, Threading, Lash Lift and Tint, Brows  and more.';
        } else if (city === 'FR-PARIS') {
          treatmentsText =
            'Beauté des ongles et des pieds, Vernis semi-permanent, Manucure OPI, Soin du visage classique, Soin du visage Dermalogica, Épilation à la cire, Maquillage, Coiffure, Barbier et plus.';
        }
        break;
      case 'OSTEO_AND_PHYSIO':
        treatments = config.getValue(
          'hero.portal.signup.medical-treatments-list',
          i18nUtils.umRegion,
          city,
        );
        break;
      case 'FITNESS':
        //TODO this should come from config or backend
        treatmentsText = t('specialities_fitness-treatments-list');
        break;
      case 'LIFESTYLE':
        //TODO this also should come from config or backend
        treatmentsText = t('specialities_lifestyle-treatments-list');
        break;
      default:
        throw new Error('Vertical is not supported yet!');
    }

    treatmentsText =
      treatmentsText ||
      (treatments &&
        treatments instanceof Array &&
        treatments
          .map((treatment: string) => {
            const translationKey = `specialities_${treatment.replace(/_/g, '-').toLowerCase()}`;
            return t(translationKey);
          })
          .join(', '));

    return (
      treatmentsText && (
        <section className="column is-6">
          <h3 className="home-explore-cities__subtitle">{title}</h3>
          <div className="home-explore-cities__treatments">
            {truncateWords(
              treatmentsText,
              MAX_SPECIALITIES,
              ', ',
              t('specialities_truncation-replacement'),
            )}
          </div>
        </section>
      )
    );
  },
);

export default withTranslation('home')(ActiveCityTabContent);
